<template>
  <section class="invoice-preview-wrapper">
    <b-row class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="12" md="12">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="
                d-flex
                justify-content-between
                flex-md-row flex-column
                invoice-spacing
                mt-0
              "
            >
              <!-- Header: Left Content -->
              <div v-for="store in data_stores" :key="store.id">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar size="120px" :src="logo_oa" rounded />
                  </b-media-aside>
                  <b-media-body>
                    <h1 class="mb-0">
                      <b-badge pill variant="light-primary">
                        {{ store.store_name }}
                      </b-badge>
                    </h1>

                    <h6>
                      <hr />
                      <!-- <b-badge pill variant="light-primary"> -->
                      ✍ ประเภทธุรกิจ : {{ store.type_business_type }} <br />
                      ✍ ประเภทธุรกิจย่อย : {{ store.type_sub }}
                      <!-- </b-badge> -->
                    </h6>
                    <h6>★ ทั้งหมด {{ totalRows__brach }} สาขา</h6>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="$router.push('/store/add-brach')"
                    >
                      <feather-icon icon="FileTextIcon" class="mr-50" />
                      <span class="align-middle">เพิ่มสาขา</span>
                    </b-button>
                  </b-media-body>
                </b-media>
              </div>
              <div class="mt-md-0 mt-2">
                <!-- <settingstore @loaddata_store_id="loaddata_store_id" /> -->
              </div>
            </div>
          </b-card-body>
          <hr class="invoice-spacing" />
          <!-- สาขา -->
          <!-- สาขา -->
          <b-card-body>
            <h1>ทั้งหมด {{ totalRows__brach }} สาขา</h1>

            <b-row> </b-row>

            <b-row>
              <b-col lg="4" v-for="brach in data_brach" :key="brach.id">
                <b-card
                  no-body
                  class="card-apply-job"
                  style="border: 2px solid black; padding: 10px"
                >
                
                  <b-card-body>
                    <div align="center">
                      <h3 class="mb-0">⚡{{ brach.name }}</h3>
                    </div>
                    <section>
                      <b-card class="ecommerce-card" no-body>
                        <b-card-body>
                          <div class="item-img text-center">
                            <b-button-group class="item-img text-center">
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                @click="
                                  device(
                                    brach.pictrue,
                                    brach.id,
                                    brach.name,
                                    brach.hotspot_total,
                                    brach.hotspot
                                  )
                                "
                              >
                                <feather-icon
                                  icon="HardDriveIcon"
                                  class="mr-50"
                                />
                                <span
                                  >อุปกรณ์ {{ brach.hotspot_total }} ตัว</span
                                >
                              </b-button>
                           
                            </b-button-group>
                          </div>
                        </b-card-body>
                        <!-- Product Actions -->
                        <div class="item-options text-center">
                          <div class="item-wrapper">
                            <div class="item-cost">
                              <!-- <h4 class="item-price">{{ brach.name }}</h4> -->
                            </div>
                          </div>
                          <div class="item-wrapper">
                            <div class="item-rating">
                              <a
                                :href="href + brach.lat + ',' + brach.lng"
                                target="_blank"
                              >
                                <ul class="unstyled-list list-inline">
                                  <li class="ratings-list-item">
                                    แผนที่ ➤google map
                                  </li>
                                </ul></a
                              >
                            </div>
                          </div>
                          <b-button
                            variant="light"
                            tag="a"
                            class="btn-wishlist"
                            @click="edit_brach(brach.id)"
                          >
                            <feather-icon icon="EditIcon" class="mr-50" />
                            <span>ดูรายละเอียด</span>
                          </b-button>
                          <b-button
                            variant="primary"
                            tag="a"
                            class="btn-cart"
                            @click="
                              add_hp(
                                brach.id,
                                brach.name,
                                brach.hotspot_total,
                                brach.hotspot
                              )
                            "
                          >
                            <feather-icon icon="HardDriveIcon" class="mr-50" />
                            <span> เพิ่มอุปกรณ์ </span>
                          </b-button>
                        </div>
                      </b-card>
                    </section>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-card-body>
          <!-- สาขา -->
          <!-- สาขา -->
        </b-card>
      </b-col>
    </b-row>
    <add_hotspot
      @loaddata_brach_inby_store="loaddata_brach_inby_store"
      ref="modaladdtag"
    ></add_hotspot>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  BAvatar,
  BMediaBody,
  BMedia,
  BMediaAside,
  BBadge,
  BButtonGroup,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import api from "@/api";
import settingstore from "./setting_store.vue";
import add_hotspot from "./add_hotspot.vue";
import setstore from "@core/layouts/components/app-navbar/components/Bookmarks.vue";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    Logo,
    BAvatar,
    BMediaBody,
    BMedia,
    BMediaAside,
    BBadge,
    settingstore,
    add_hotspot,
    BButtonGroup,
    setstore,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name_user: "",
      store_name: localStorage.getItem("store_name"),
      data_brach: "",
      totalRows__brach: "",
      data_stores: "",
      totalRows: "",
      totalRows: "",
      logo_oa:localStorage.getItem('logo'),
      setting_network: 0,
      total: 0,
      href: "http://maps.google.com/maps?q=",
    };
  },
  created() {
    if (localStorage.getItem("oneSelect") == 1) {
      localStorage.setItem("oneSelect", 2);
      window.location.reload();
    }

  },
  mounted() {
    if (!localStorage.getItem("store_id")) {
      this.$swal({
        title: "กรูณาเลือกร้านค้าก่อน!",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      this.$router.push({ name: "store" });
    }
    this.name_user = JSON.parse(localStorage.getItem("userData"));
    this.loaddata_store_id();
    this.loaddata_brach_inby_store();
  },
  methods: {
    async loaddata_store_id() {
      var userData = JSON.parse(localStorage.getItem("userData"));
      const params = {
        store_id: localStorage.getItem("store_id"),
        user_main: userData.user_main,
      };
      api
        .post("getstore_id", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.data_stores = response.data.data;
          console.log("response.data.data :>> ", response.data);
          this.setting_network = response.data.setting_network;
          console.log("this.setting_network :>> ", this.setting_network);
        })
        .catch((error) => {
          // window.location.reload();
        });
    },
    async loaddata_brach_inby_store() {
      const params = {
        store_id: localStorage.getItem("store_id"),
      };
      // console.log("params :loaddata_brach_inby_store>> ", params);
      api
        .post("get_hp_brach_in_store", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.data_brach = response.data.data;
          this.totalRows__brach = response.data.total;
          // console.log(response.data);
          // console.log("this.data_brach :>> ", this.data_brach);
          // console.log("this.totalRows__brach :>> ", this.totalRows__brach);
          for (var i = 0; i < this.totalRows__brach; i++) {
            // console.log(
            //   "this.data_brach :>> " + i,
            //   this.data_brach[i].hotspot_total
            // );
            //   this.data_brach[i]["hotports"] =
            //     this.data_brach[i].hotport.split(",");
            //   this.data_brach[i]["hotports_total"] =
            //     this.data_brach[i].hotport.split(",").length;
          }
          // console.log("this.data_brach :>> ", this.data_brach);
        })
        .catch((error) => {
          // window.location.reload();
        });
    },
    // custom image
    device(pictrue, id, brach, totalhot, hots) {
      var text = "อุปกรณ์ที่เชื่อมต่อ " + totalhot + " ตัว <br/><br/>";
      var n = 1;
      for (var i = 0; i < totalhot; i++) {
        console.log(hots[i].mac);
        console.log(text);
        text += n + ". " + hots[i].mac + " /" + hots[i].description + "<br/>";
        n += 1;
      }
      this.$swal({
        title: brach,
        text: "Modal with a custom image.",
        html: text,
        // imageUrl: pictrue,
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: "Custom image",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    timeopenstore(
      id,
      brach,
      sunday_start,
      sunday_end,
      monday_start,
      monday_end,
      tuesday_start,
      tuesday_end,
      wednesday_start,
      wednesday_end,
      thursday_start,
      thursday_end,
      friday_start,
      friday_end,
      saturay_start,
      saturay_end
    ) {
      console.log("id :>> ", id);

      var text =
        "เวลาเปิดทำการ <br />" +
        "อา " +
        sunday_start +
        " น. ถึง " +
        sunday_end +
        " น. <br />" +
        "จ " +
        monday_start +
        " น. ถึง " +
        monday_end +
        " น. <br />" +
        "อ " +
        tuesday_start +
        " น. ถึง " +
        tuesday_end +
        " น. <br /> " +
        "พ " +
        wednesday_start +
        " น. ถึง " +
        wednesday_end +
        " น. <br />" +
        "พฤ " +
        thursday_start +
        " น. ถึง " +
        thursday_end +
        " น.<br />" +
        "ศ " +
        friday_start +
        " น. ถึง " +
        friday_end +
        " น. <br />" +
        "ส " +
        saturay_start +
        " น. ถึง " +
        saturay_end +
        " น. <br />";
      // console.log("text :>> ", text);
      this.$swal({
        title: brach,
        html: text,
        // imageUrl: pictrue,
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: "Custom image",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    edit_brach(id) {
      this.$router.push("/store/edit");
      localStorage.setItem("brach_id", id);
    },
    add_hp(id_beach, name_beach, hotspot_total, hotspot) {
  
        this.$refs.modaladdtag.showmodeladdHP(
          id_beach,
          name_beach,
          hotspot_total,
          hotspot
        );
      
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss"></style>
